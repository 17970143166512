import React, { createContext, useCallback, useContext, useState } from 'react';
import { SubmitDejalaIrAPI } from '../API/Campaigns/DejalaIr/DejalaIrAPI';
import DejarlaIrDialog from '../Components/Dialogs/DejarlaIrDialog';
import { config } from '../Configs';
import { UserContext } from './UserContext';

/**
 * Contexto para la campaña DejalaIr
 */
export const DejalaIrContext = createContext({
  validateCampaign: null,
  openModal: false,
  setOpenModal: null
});

/**
 * Proveedor para el contexto de la campaña DejalaIr
 * @param {*} children
 * @returns
 */
export const DejalaIrProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);
  const [contract, setContract] = useState(null);
  const currentUser = useContext(UserContext);
  let urlGame = null;

  if (config.campaign.game.url) {
    urlGame = new URL(config.campaign.game.url);
    urlGame.searchParams.set(
      'utm_user-name',
      currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ''
    );
    urlGame.searchParams.set('utm_user-contract', contract);
  }

  /**
   * Cierra el modal de DejalaIr
   * @returns {void}
   */
  const handleCloseDejarlaIr = () => {
    setContract(null);
    setOpenModal(false);
  };

  /**
   * Crea el iframe con el juego
   * @returns {JSX.Element} iframe
   */
  /**
   * Valida si el contrato es válido para la campaña DejalaIr
   * @param {*} contractId
   * @returns
   */
  const validateCampaign = useCallback(
    async contractId => {
      if (!contractId) {
        return false;
      }

      setContract(contractId);

      const validateData = await SubmitDejalaIrAPI({
        token: currentUser ? currentUser.token : '',
        contractId
      });

      if (!validateData.success) {
        return false;
      }

      setOpenModal(true);
      return true;
    },
    [currentUser, setContract, setOpenModal]
  );

  const value = {
    validateCampaign,
    openModal,
    setOpenModal
  };

  return (
    <DejalaIrContext.Provider value={value}>
      {children}
      {openModal && urlGame && (
        <DejarlaIrDialog urlGame={urlGame} onClose={handleCloseDejarlaIr} />
      )}
    </DejalaIrContext.Provider>
  );
};
