import axios from 'axios';
import { config } from '../../../Configs';

/**
 * SubmitDejalaIrAPI - Función para enviar la solicitud de dejala ir
 * @param {*} token
 * @param {*} contractId
 * @returns
 */
export const SubmitDejalaIrAPI = async ({
  token = null,
  contractId = null
}) => {
  const reason = `${config.campaign.name}`;

  if (!config.campaign.name) {
    return { success: false, error: null };
  }

  try {
    const configObj = {};

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }

    const response = await axios.post(
      `${config.api.url}/campaigns`,
      {
        reason,
        contractId
      },
      configObj
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};
