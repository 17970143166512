import axios from 'axios';
import { config } from '../../../Configs';
const reason = `${config.campaign.name}`;
const PAGAGANA_CAMPAIGN_PREFIX = 'PAGAGANA-EFG-';
const isPagaGanaCampaignReason = reasonName =>
  reasonName.startsWith(PAGAGANA_CAMPAIGN_PREFIX);
/**
 * SubmitPagarGanaAPI - Función para enviar la solicitud de pagar gana
 * @param {*} token
 * @param {*} transactionId
 * @returns
 */

export const SubmitPagarGanaAPI = async ({ token = null, transactionId }) => {
  if (!reason) {
    return { success: false, error: null };
  }

  try {
    const configObj = {};

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }

    const response = await axios.post(
      `${config.api.url}/campaigns/validate/pagaganaefg`,
      {
        reason,
        transactionId
      },
      configObj
    );

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error };
  }
};
/**
 * IsActivePagarGanaApi - Función para validar que la campaña siga activa
 * @param {*} token
 * @returns
 */
export const IsActivePagarGanaApi = async ({ token = null }) => {
  if (isPagaGanaCampaignReason(reason)) {
    try {
      const configObj = {};

      if (token) {
        configObj.headers = {
          Authorization: token
        };
      }

      const response = await axios.get(
        `${config.api.url}/campaigns/${reason}`,
        configObj
      );

      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error };
    }
  }
};
