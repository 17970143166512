import React from 'react';
import BaseDialog from './BaseDialog';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { SlideUpTransition } from '../Transitions/Transitions';
const DejarlaIrDialog = props => {
  const { onClose, urlGame } = props;
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const iframe = () => (
    <iframe
      src={urlGame}
      width="100%"
      height="98%"
      frameBorder="0"
      title="DejalaIr"
    ></iframe>
  );
  return (
    <BaseDialog
      open={true}
      handleClose={onClose}
      title="¡Acumula puntos jugando!"
      maxWidth="lg"
      disableBackdropClick={true}
      fullWidth={true}
      actions={() => null}
      content={iframe}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      contentSize="large"
    />
  );
};

export default DejarlaIrDialog;
