/* eslint-disable no-inline-comments */
import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  useMediaQuery
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import BaseDialog from './BaseDialog';
import BaseButton from '../Buttons/BaseButton';
import TextInput from '../Inputs/TextInput';
import CompositeSelectInput from '../Inputs/CompositeSelectInput';
import FlagColombia from '../CustomIcons/Flags/FlagColombia';
import NumberInput from '../Inputs/NumberInput';
import PhoneVerificationDialog from './PhoneVerificationDialog';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import { UserContext } from '../../Contexts/UserContext';

import { MakeAppointmentAPI } from '../../API/BrillaInsurances/BrillaInsurancesAPI';
import { isPrintableAscii } from '../../Utils/Misc/String';

import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import {
  IDENTIFICATION_OPTIONS,
  InsuranceProcess,
  PractiSeguroProduct,
  Insurer
} from '../../Enums/insurances';
import { history } from '../../Routes/history';
import {
  TERMS,
  TERMS_ROUTES
} from '../../Views/Information/Information/Information';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import { parseLocalDateTo } from '../../Utils/Date/parse';

const schema = yup.object({
  firstName: yup
    .string()
    .min(4, 'Mínimo 4 caracteres')
    .max(150, 'Máximo 150 caracteres')
    .required('Ingresa los nombres'),
  lastName: yup
    .string()
    .min(4, 'Mínimo 4 caracteres')
    .max(150, 'Máximo 150 caracteres')
    .required('Ingresa los apellidos'),
  identificationType: yup
    .string()
    .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
    .required('Elija un tipo válido'),
  identification: yup
    .number()
    .typeError('El número de identificación debe ser válido')
    .positive('El número de identificación debe ser válido')
    .test('integer', 'El número de identificación debe ser válido', val => {
      return val && val % 1 === 0;
    })
    .required('Ingresa el número de identificación'),
  dateOfBirth: yup
    .date()
    .min('1901-01-01', 'Fecha no válida')
    .max(new Date(), 'Fecha no válida')
    .typeError('La fecha  debe ser válida')
    .required('Elija una fecha válida'),
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .required('Debes ingresar un correo'),
  phone: yup
    .number()
    .typeError('El número de teléfono debe ser válido')
    .positive('El número de teléfono debe ser válido')
    .test('integer', 'El número de teléfono debe ser válido', val => {
      return val && val % 1 === 0;
    })
    .test('length', 'El número de teléfono debe ser de 7 o 10 dígitos', val => {
      return (
        (val && val.toString().length === 7) ||
        (val && val.toString().length === 10)
      );
    })
    .required('Ingresa el número de teléfono')
});

const InsuranceAppointmentDialog = props => {
  const { open, handleClose, contractId, product, hasPolicy } = props;

  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const [acceptsTerms, setAcceptsTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const onChangeAcceptTerms = useCallback(() => {
    setAcceptsTerms(!acceptsTerms);
  }, [acceptsTerms]);

  const { register, getValues, errors, handleSubmit, control } = useForm({
    validationSchema: schema,
    submitFocusError: true
  });

  useEffect(() => {
    setLoading(false);
  }, [open]);
  const openPhoneVerification = () => {
    setLoading(true);
    setOpenVerificationDialog(true);
  };

  const handlePhoneVerification = state => {
    setLoading(false);
    setOpenVerificationDialog(state);
  };

  const makeAppointment = async () => {
    setLoading(true);
    const values = getValues();

    const appointmentData = {
      insurer: Insurer.AlfaSeguros,
      product: PractiSeguroProduct.product,
      contractId,
      phone: values.phone,
      phoneCountryCode: values.phoneCountryCode,
      identification: parseInt(values.identification, 10),
      identificationType: values.identificationType,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      dateOfBirth: parseLocalDateTo(values.dateOfBirth).toISOString()
    };

    const response = await MakeAppointmentAPI(
      currentUser.token,
      parseInt(currentUser.identification, 10),
      appointmentData
    );

    const responseData = response.data;

    if (responseData) {
      setLoading(false);
      handleClose();
      history.push(`${ROUTE_NAMES.managePractiseguro}`, {
        confirmation: {
          applicationId: responseData.id,
          process: InsuranceProcess.Appointment
        },
        product
      });
    } else {
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
    setLoading(false);
  };

  const renderContent = () => {
    return (
      <Fragment>
        {hasPolicy ? (
          <>
            {openVerificationDialog && (
              <PhoneVerificationDialog
                open={openVerificationDialog}
                setDialog={handlePhoneVerification}
                setAlert={setAlert}
                title="Validar número celular"
                successCallback={makeAppointment}
                formPhone={getValues().phone}
                formPhoneCountryCode={getValues().phoneCountryCode}
              />
            )}
            <Typography className={clsx(classes.text, classes.dialogText)}>
              Completa los siguientes datos para solicitar la asesoría
            </Typography>
            <Grid container className={clsx(classes.content, classes.text)}>
              <Grid item xs={12} sm={6} className={classes.field}>
                <TextInput
                  label="Nombres"
                  name="firstName"
                  inputRef={register}
                  fullWidth={true}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Apellidos"
                  name="lastName"
                  inputRef={register}
                  fullWidth={true}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                />
              </Grid>
            </Grid>
            <Grid container className={clsx(classes.content, classes.text)}>
              <Grid
                item
                xs={12}
                sm={6}
                className={clsx(classes.field, classes.extraPaddingTop)}
              >
                <CompositeSelectInput
                  label="Identificación"
                  options={IDENTIFICATION_OPTIONS}
                  TextInputProps={{
                    label: 'Identificación',
                    name: 'identification',
                    defaultValue: '',
                    inputRef: register,
                    fullWidth: true
                  }}
                  SelectInputProps={{
                    name: 'identificationType',
                    defaultValue: 'CC',
                    inputRef: register,
                    control
                  }}
                  error={Boolean(errors.identification)}
                  helperText={
                    errors.identification && errors.identification.message
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                className={classes.phoneContainer}
              >
                <Grid item xs={5} className={classes.prefixContainer}>
                  <Controller
                    as={
                      <NumberInput
                        id="MyProfile_input_countryCode"
                        autoComplete="tel-country-code"
                        required
                        InputProps={{
                          startAdornment: (
                            <FlagColombia size={20} style={{ flexShrink: 0 }} />
                          )
                        }}
                        format="+###"
                        placeholder="57"
                        fullWidth
                        label="Prefijo"
                        error={Boolean(errors.phoneCountryCode)}
                        helperText={
                          errors.phoneCountryCode &&
                          errors.phoneCountryCode.message
                        }
                        margin="none"
                      />
                    }
                    control={control}
                    name="phoneCountryCode"
                    defaultValue={'57'}
                    onChangeName="onValueChange"
                    onChange={values => {
                      if (values[0]) {
                        return values[0].value;
                      }
                      return '';
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    as={
                      <NumberInput
                        id="MyProfile_input_phone"
                        autoComplete="tel-local"
                        fullWidth
                        margin="none"
                        label="Teléfono"
                        error={Boolean(errors.phone)}
                        helperText={errors.phone && errors.phone.message}
                      />
                    }
                    control={control}
                    name="phone"
                    onChangeName="onValueChange"
                    onChange={values => {
                      if (values[0]) {
                        return values[0].value;
                      }
                      return '';
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container className={clsx(classes.content, classes.text)}>
              <Grid
                item
                xs={12}
                sm={6}
                className={clsx(classes.field, classes.extraPaddingTop)}
              >
                <TextInput
                  label="Fecha de nacimiento"
                  name="dateOfBirth"
                  type="date"
                  inputRef={register}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.dateOfBirth)}
                  helperText={errors.dateOfBirth && errors.dateOfBirth.message}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Correo electrónico"
                  name="email"
                  value={currentUser.email}
                  inputRef={register}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.termsContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="acceptsTerms"
                    checked={acceptsTerms}
                    onClick={onChangeAcceptTerms}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    value="checkedI"
                  />
                }
                label={
                  <Typography className={classes.terms}>
                    He leído y acepto los{' '}
                    <a
                      className={classes.link}
                      href={`${ROUTE_NAMES.information}/${
                        TERMS_ROUTES[TERMS.definitions]
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      términos y condiciones
                    </a>{' '}
                    de uso, la{' '}
                    <a
                      className={classes.link}
                      href={`${ROUTE_NAMES.information}/${
                        TERMS_ROUTES[TERMS.definitions]
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      política de privacidad
                    </a>{' '}
                    y autorizo el tratamiento de mis datos personales.
                  </Typography>
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <div className={classes.noPolicyTextContainer}>
              <Typography className={clsx(classes.content, classes.text)}>
                Actualmente, no tienes una poliza. Para conocer más acerca del
                producto, te sugerimos comunicarte con el área de soporte
              </Typography>
            </div>
            <Grid
              container
              justify="center"
              className={classes.buttonContainer}
            >
              <Grid item xs={12} className={classes.contactButton}>
                <BaseButton
                  color="default"
                  variant="text"
                  size="small"
                  className={classes.whatsAppButton}
                  startIcon={<WhatsAppIcon />}
                  fullWidth={isMobileSize}
                >
                  Escríbenos
                </BaseButton>
              </Grid>
              <Grid item xs={12} className={classes.contactButton}>
                <BaseButton
                  color="default"
                  variant="text"
                  size="small"
                  className={classes.callUsButton}
                  startIcon={<LocalPhoneIcon />}
                  fullWidth={isMobileSize}
                >
                  Llámanos
                </BaseButton>
              </Grid>
            </Grid>
          </>
        )}
      </Fragment>
    );
  };
  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={handleSubmit(openPhoneVerification)}
          color="primary"
          size="small"
          loading={loading}
          disabled={!acceptsTerms}
        >
          Solicitar
        </BaseButton>
      </Fragment>
    );
  };
  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      title="Solicitar asesoría"
      content={renderContent}
      actions={hasPolicy && renderActions}
      contentStyle={classes.dialogContent}
      fullScreen={isMobileSize}
    />
  );
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    minHeight: 0,
    padding: theme.spacing(0, 2)
  },
  content: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      paddingBottom: 0
    }
  },
  dialogText: {
    padding: theme.spacing(2, 0)
  },
  field: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingRight: 0
    }
  },
  text: {
    fontSize: 14
  },
  policy: {
    margin: theme.spacing(2, 0)
  },
  policyNameText: {
    color: theme.palette.text.disabled,
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(4)
  },
  contactButton: {
    margin: theme.spacing(1),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexBasis: 'auto'
    }
  },
  termsContainer: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2, 0)
    }
  },
  terms: {
    fontSize: 14
  },
  whatsAppButton: {
    color: '#18CC48',
    backgroundColor: '#D6FFE1'
  },
  callUsButton: {
    color: '#104EB2',
    backgroundColor: '#E4EBF6'
  },
  phoneContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingTop: theme.spacing(2)
    }
  },
  prefixContainer: {
    paddingRight: theme.spacing(2)
  },
  extraPaddingTop: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingTop: theme.spacing(1)
    }
  },
  noPolicyTextContainer: {
    padding: theme.spacing(1, 0)
  }
}));

export default InsuranceAppointmentDialog;
