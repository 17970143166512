/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

import FlagColombia from '../../../Components/CustomIcons/Flags/FlagColombia';
import TextInput from '../../../Components/Inputs/TextInput';
import CompositeSelectInput from '../../../Components/Inputs/CompositeSelectInput';
import NumberInput from '../../../Components/Inputs/NumberInput';
import Title from '../../../Components/Labels/Title';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import PhoneVerificationDialog from '../../../Components/Dialogs/PhoneVerificationDialog';
import Recaptcha from '../../../Components/Captcha/Recaptcha';

import { UserContext } from '../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../Contexts/StepperContext';

import { ValidateIdentificationAPI } from '../../../API/UserAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';

import clsx from 'clsx';
import { config } from '../../../Configs';
import { isPrintableAscii } from '../../../Utils/Misc/String';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { TERMS, TERMS_ROUTES } from '../../Information/Information/Information';
import { IDENTIFICATION_OPTIONS } from '../SubrogationRequest_enums';
import { PrivacyPoliciesLink } from '../../../Configs/Links';
import { parseLocalDateTo } from '../../../Utils/Date/parse';

const schema = yup.object({
  firstName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa los nombres'),
  lastName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa los apellidos'),
  identificationType: yup
    .string()
    .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
    .required('Elija un tipo valido'),
  identification: yup
    .number()
    .typeError('El número de identificación debe ser válido')
    .positive('El número de identificación debe ser válido')
    .test('integer', 'El número de identificación debe ser válido', val => {
      return val % 1 === 0;
    })
    .required('Ingresa el número de identificación'),
  expeditionDate: yup
    .date()
    .max(new Date(), 'Fecha no valida')
    .typeError('La fecha debe ser válida')
    .required('Elija una fecha valida'),
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .required('Ingresa un correo'),
  phone: yup
    .string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .required('Ingresa un teléfono'),
  phoneCountryCode: yup
    .string()
    .trim()
    .max(3, 'Debe tener máximo 3 dígitos')
    .required('Debe ser válido'),
  terms: yup.boolean().oneOf([true], 'Este campo es requerido.')
});

const PersonalDataStep = props => {
  const {
    formId,
    setCanSubmit,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert,
    setGoBack
  } = props;
  const classes = useStyles();

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const currentUser = useContext(UserContext);

  const theme = useTheme();
  const isMobileSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const {
    register,
    watch,
    errors,
    control,
    handleSubmit,
    setValue,
    getValues
  } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      firstName: currentUser ? currentUser.firstName : '',
      lastName: currentUser ? currentUser.lastName : '',
      terms: false,
      identificationType:
        currentUser && currentUser.identificationType
          ? currentUser.identificationType
          : 'CC',
      identification: currentUser ? currentUser.identification : '',
      email: currentUser ? currentUser.email : '',
      phone: currentUser && currentUser.phone ? currentUser.phone : '',
      ...stepperData
    }
  });

  const [loading, setLoading] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [submittedValues, setSubmittedValues] = useState(null);
  const [acceptsTerms, setAcceptsTerms] = useState(false);

  const recaptchaRef = useRef(null);
  const referenceRef = useRef(null);

  const watchForm = watch(['phoneCountryCode', 'phone']);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const onSuccessUserData = useCallback(
    formValues => {
      const formattedDate = moment(formValues.expeditionDate).format(
        'YYYY-MM-DD'
      );

      setData(d => ({
        ...d,
        ...formValues,
        expeditionDate: formattedDate,
        aniData: getValues('aniData')
      }));
      setCurrentStep(step => step + 1);
    },
    [setData, setCurrentStep, getValues]
  );

  const onChangeAcceptTerms = useCallback(() => {
    setAcceptsTerms(!acceptsTerms);
  }, [acceptsTerms]);

  const handleOpenVerificationDialog = useCallback(
    values => {
      const {
        phone: currentPhone,
        phoneCountryCode: currentCountryCode
      } = values;
      const previousPhone = stepperData.phone;
      const previousCountryCode = stepperData.phoneCountryCode;
      const phoneHasChanged = previousPhone !== currentPhone;
      const countryCodeHasChanged = previousCountryCode !== currentCountryCode;

      if (!phoneHasChanged && !countryCodeHasChanged) {
        setSubmittedValues(null);
        onSuccessUserData(values);
        return;
      }
      setSubmittedValues(values);

      if (phoneHasChanged || countryCodeHasChanged) {
        setOpenVerificationDialog(true);
      }
    },
    [stepperData.phone, stepperData.phoneCountryCode, onSuccessUserData]
  );

  const verifyUserData = useCallback(
    async values => {
      const captchaValue = captcha;
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptcha(null);
      }

      const {
        identification,
        identificationType,
        expeditionDate,
        lastName
      } = values;
      const formattedDate = moment(expeditionDate).format('YYYY-MM-DD');

      const lastNameHasChanged = stepperData.lastName !== lastName;

      const identificationHasChanged =
        stepperData.identification !== identification;

      const expeditionDateHasChanged =
        stepperData.expeditionDate !== formattedDate;

      if (
        identificationHasChanged ||
        expeditionDateHasChanged ||
        lastNameHasChanged
      ) {
        setLoading(true);

        const identificationData = {
          identification,
          identificationType,
          expeditionDate: parseLocalDateTo(expeditionDate).toISOString(),
          lastName: lastName.trim()
        };

        const response = await ValidateIdentificationAPI(
          currentUser.token,
          identificationData,
          captchaValue
        );

        if (!response.success) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: extractErrorMessage(response).message
          });
          return;
        }
        const { data } = response.data;
        register('aniData');
        setValue('aniData', data.info);

        if (stepperData.signDocumentURL) {
          setData(d => ({
            ...d,
            signDocumentURL: null,
            documents: null
          }));
        }
      }
      setLoading(false);
      handleOpenVerificationDialog(values);
    },
    [
      currentUser.token,
      setAlert,
      stepperData.expeditionDate,
      stepperData.identification,
      stepperData.signDocumentURL,
      stepperData.lastName,
      handleOpenVerificationDialog,
      captcha,
      register,
      setValue,
      setData
    ]
  );

  const onBackward = useCallback(() => {
    setCurrentStep(step => step - 1);
  }, [setCurrentStep]);

  const handleCopyPaste = evt => {
    evt.preventDefault();
  };

  // Props setters
  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit
  ]);

  useEffect(() => {
    setCanSubmit(
      !loading && !loadingCaptcha && Boolean(captcha) && acceptsTerms
    );
  }, [loading, loadingCaptcha, setCanSubmit, captcha, acceptsTerms]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  return (
    <div className={clsx(classes.innerContentContainer, classes.centerContent)}>
      <div className={classes.root}>
        {loading && <ModalProgress message="Consultando" />}
        {loadingCaptcha && <ModalProgress message="Cargando" />}
        {openVerificationDialog && (
          <PhoneVerificationDialog
            open={openVerificationDialog}
            setDialog={setOpenVerificationDialog}
            setAlert={setAlert}
            title="Validar número celular"
            successCallback={() => onSuccessUserData(submittedValues)}
            formPhone={watchForm.phone}
            formPhoneCountryCode={watchForm.phoneCountryCode}
          />
        )}
        <Title title="Valida tus datos personales" className={classes.title} />

        <form id={formId} onSubmit={handleSubmit(verifyUserData)}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={6}
              className={clsx(classes.item, classes.leftItem)}
            >
              <TextInput
                label="Nombres*"
                inputProps={{ maxLength: 100 }}
                inputRef={register}
                fullWidth
                name="firstName"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName && errors.firstName.message}
                onCut={handleCopyPaste}
                onCopy={handleCopyPaste}
                onPaste={handleCopyPaste}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.item}>
              <TextInput
                label="Apellidos*"
                inputProps={{ maxLength: 100 }}
                inputRef={register}
                fullWidth
                name="lastName"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
                onCut={handleCopyPaste}
                onCopy={handleCopyPaste}
                onPaste={handleCopyPaste}
                margin="none"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={clsx(classes.item, classes.leftItem)}
            >
              <CompositeSelectInput
                label="Identificación*"
                options={IDENTIFICATION_OPTIONS}
                TextInputProps={{
                  label: 'Identificación*',
                  name: 'identification',
                  defaultValue: currentUser.identification || '',
                  inputRef: register,
                  fullWidth: true,
                  margin: 'none'
                }}
                SelectInputProps={{
                  name: 'identificationType',
                  defaultValue: currentUser.identificationType || 'CC',
                  control
                }}
                error={Boolean(errors.identification)}
                helperText={
                  errors.identification && errors.identification.message
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.item}>
              <TextInput
                label="Fecha de expedición*"
                inputProps={{ maxLength: 100 }}
                fullWidth
                inputRef={register}
                type="date"
                name="expeditionDate"
                helperText={
                  errors.expeditionDate && errors.expeditionDate.message
                }
                error={Boolean(errors.expeditionDate)}
                InputLabelProps={{ shrink: true }}
                margin="none"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid
                  item
                  xs={5}
                  className={clsx(classes.item, classes.prefixItem)}
                >
                  <Controller
                    as={
                      <NumberInput
                        id="SubrogationRequest_input_countryCode"
                        autoComplete="tel-country-code"
                        InputProps={{
                          startAdornment: (
                            <FlagColombia size={20} style={{ flexShrink: 0 }} />
                          )
                        }}
                        format="+###"
                        placeholder="57"
                        fullWidth
                        label="Prefijo"
                        error={Boolean(errors.phoneCountryCode)}
                        helperText={
                          errors.phoneCountryCode &&
                          errors.phoneCountryCode.message
                        }
                        margin="none"
                      />
                    }
                    control={control}
                    name="phoneCountryCode"
                    defaultValue={currentUser.phoneCountryCode || '57'}
                    onChangeName="onValueChange"
                    onChange={values => {
                      if (values[0]) {
                        return values[0].value;
                      }
                      return '';
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={7}
                  className={clsx(classes.item, classes.leftItem)}
                >
                  <Controller
                    as={
                      <NumberInput
                        id="SubrogationRequest_input_phone"
                        autoComplete="tel-local"
                        fullWidth
                        margin="none"
                        label="Número celular"
                        error={Boolean(errors.phone)}
                        helperText={errors.phone && errors.phone.message}
                      />
                    }
                    control={control}
                    name="phone"
                    onChangeName="onValueChange"
                    onChange={values => {
                      if (values[0]) {
                        return values[0].value;
                      }
                      return '';
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              ref={referenceRef}
              className={classes.item}
            >
              <TextInput
                label="Correo electrónico"
                inputProps={{ maxLength: 100 }}
                inputRef={register}
                fullWidth
                name="email"
                error={Boolean(errors.email)}
                onCut={handleCopyPaste}
                onCopy={handleCopyPaste}
                onPaste={handleCopyPaste}
                margin="none"
                disabled
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              ref={referenceRef}
              className={classes.inputContainer}
            >
              <Recaptcha
                captchaRef={recaptchaRef}
                referenceEl={referenceRef}
                locale={'es'}
                sitekey={config.recaptcha.siteKey}
                onResolved={onCaptchaResolved}
                onExpired={onCaptchaExpired}
                onLoaded={onCaptchaLoaded}
                style={{
                  marginTop: 0,
                  transform: isMobileSize ? 'scale(1.13,1)' : 'scale(1.1,0.9)'
                }}
              />
            </Grid>
            <Grid item xs={12} ref={referenceRef} className={classes.item}>
              <FormControlLabel
                className={classes.item}
                control={
                  <Checkbox
                    id="SubrogationRequest_check_terms"
                    checked={acceptsTerms}
                    onClick={onChangeAcceptTerms}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    value="checkedI"
                  />
                }
                label={
                  <Typography className={classes.terms}>
                    He leído y acepto los{' '}
                    <a
                      href={`${ROUTE_NAMES.information}/${
                        TERMS_ROUTES[TERMS.introduction]
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      términos y condiciones
                    </a>{' '}
                    de uso, la{' '}
                    <a
                      href={PrivacyPoliciesLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      política y aviso de privacidad
                    </a>{' '}
                    y autorizo el tratamiento de mis datos personales.
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      marginBottom: theme.spacing(2)
    }
  },
  innerContentContainer: {
    maxWidth: 700
  },
  centerContent: {
    margin: '0 auto'
  },
  title: {
    margin: theme.spacing(2, 0, 4)
  },
  item: {
    paddingBottom: theme.spacing(3)
  },
  prefixItem: {
    paddingRight: theme.spacing(2)
  },
  leftItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingRight: 0
    }
  },
  terms: {
    fontSize: 13
  },
  inputContainer: {
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(),
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

export default PersonalDataStep;
