import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';

import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import { HELP_TEXT } from '../../../Utils/enums';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import TextInput from '../../../Components/Inputs/TextInput';
import QuestionMark from '../../../Components/Adornments/QuestionMark';
import Recaptcha from '../../../Components/Captcha/Recaptcha';
import BaseButton from '../../../Components/Buttons/BaseButton';
import { GetSingleContractAPI } from '../../../API/Contracts/ContractsAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { Company } from '../../../Configs/general';
import { config } from '../../../Configs';
import ContractCard from '../../../Components/Cards/ContractCard';
import { history } from '../../../Routes/history';
import { CONTRACT_TYPES } from '../../Contracts/contract_enums';
import Title from '../../../Components/Labels/Title';
import FooterStepper from '../../../Components/Stepper/FooterStepper';
import { InvoiceHelpType } from '../../../Enums/invoices';

const schema = yup.object({
  id: yup
    .number()
    .required(
      `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
    )
    .typeError(
      `El número de ${Company.contractConjugation.regular.singular.main} debe ser válido`
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? undefined : value;
    })
});

const PeriodicRevisionQuery = ({
  selectedContractInfo,
  setSelectedContractInfo,
  setValidatedContract
}) => {
  const {
    register,
    setValue,
    control,
    errors,
    formState: { isValid },
    handleSubmit
  } = useForm({
    validationSchema: schema,
    mode: 'all'
  });

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [isContract, setIsContract] = useState(false);

  const setAlert = useContext(AlertsDispatchContext);

  const inputRef = useRef(null);
  const recaptchaRef = useRef(null);
  const authToken = null;

  const targetForm = 'SchedulingDialogForm';

  useEffect(() => {
    if (selectedContractInfo) {
      setValue('id', selectedContractInfo.id);
    }
  }, [setValue, selectedContractInfo]);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const onClickHandler = useCallback(
    async data => {
      const captchaValue = captcha;
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptcha(null);
      }

      setLoading(true);

      const response = await GetSingleContractAPI(
        data.id,
        authToken,
        captchaValue
      );
      if (response.success) {
        const contractInfo = response.data.data;
        contractInfo.id = data.id;

        if (contractInfo.type === CONTRACT_TYPES.Industrial) {
          history.replace('/');
          return;
        }
        setSelectedContractInfo(contractInfo);
        setIsContract(true);
      } else {
        setValue('id', '');
        setIsContract(false);

        const errorMessage = extractErrorMessage(response).message;
        setAlert({
          type: 'error',
          message: errorMessage
        });
      }
      setLoading(false);
    },
    [setLoading, captcha, setAlert, setValue, setSelectedContractInfo]
  );

  const goNext = useCallback(() => {
    setValidatedContract(true);
  }, [setValidatedContract]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  return (
    <>
      <Container maxWidth="md">
        <div className={classes.container}>
          <Title title="Revisión periódica" />
          <form id={targetForm} onSubmit={handleSubmit(goNext)}>
            <Typography className={classes.text}>
              Ingresa el número de{' '}
              {Company.contractConjugation.regular.singular.main} del cual
              deseas gestionar su revisión periódica
            </Typography>
            <Grid container className={classes.inputContainer}>
              <InvoiceHelpDialog
                open={helpDialogOpen}
                type={InvoiceHelpType.Contract}
                onClose={() => setHelpDialogOpen(false)}
                title={HELP_TEXT.contract}
              />
              <Grid item sm={5} xs={12} className={classes.inputContainerItem}>
                <TextInput
                  label={`Número de ${Company.contractConjugation.regular.singular.main}`}
                  autoFocus
                  inputRef={register}
                  control={control}
                  fullWidth
                  disabled={loading}
                  variant={'outlined'}
                  name="id"
                  error={Boolean(errors.id)}
                  InputProps={{
                    endAdornment: <QuestionMark onClick={onHelpClick} />,
                    inputProps: {
                      type: 'number',
                      inputMode: 'numeric'
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                ref={inputRef}
                className={classes.recaptchaContainer}
              >
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  heightScale={0.75}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </Grid>
              <Grid item sm={3} xs={12} className={classes.buttonContainer}>
                <BaseButton
                  type="submit"
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleSubmit(onClickHandler)}
                  disabled={
                    captcha === 'disable-captcha-validation' ||
                    Boolean(!captcha) ||
                    !isValid ||
                    loadingCaptcha ||
                    loading
                  }
                  fullWidth
                >
                  Consultar
                </BaseButton>
              </Grid>
            </Grid>
            <SimpleDivider />
            {isContract && (
              <Grid item xs={12} className={classes.contractContainer}>
                <ContractCard
                  data={selectedContractInfo}
                  className={classes.contract}
                  loading={loading}
                  showName={false}
                  showStratum={false}
                />
              </Grid>
            )}
          </form>
        </div>
      </Container>
      <FooterStepper targetForm={targetForm} disabledNext={!isContract} />
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(10),
    height: '100%'
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  text: {
    fontSize: 14
  },
  inputContainer: {
    marginTop: theme.spacing()
  },
  inputContainerItem: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  optionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  optionItemAlias: {
    color: '#B8B8B8',
    fontSize: 12
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: 15,
      marginTop: 0
    }
  },
  button: {
    minHeight: 58
  },
  recaptchaContainer: {
    padding: 0,
    overflow: 'hidden'
  },
  contractCard: {
    width: '100%',
    marginBottom: theme.spacing(4)
  },
  emptyState: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  contractContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingBottom: 0
    }
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  }
}));

export default PeriodicRevisionQuery;
