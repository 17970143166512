import moment from 'moment-timezone';

// This is useful when you only need to correctly parse the day, month and year
// to another time zone
export const parseLocalDateTo = (localDate, timeZone = 'America/Bogota') => {
  let momentDate = moment(localDate);

  if (!momentDate.isValid()) {
    momentDate = moment();
  }

  // This is one of the only ways to get the correct date regardless of the local time zone
  // of the device. More information in "Parsing in Zone" in the Moment Timezone documentation
  const year = momentDate.year();
  const month = momentDate.month();
  const day = momentDate.date();

  const parsedDateWithTimeZone = moment.tz({ year, month, day }, timeZone);

  return parsedDateWithTimeZone;
};
