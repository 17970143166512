import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import TextInput from '../../../Components/Inputs/TextInput';
import FlagColombia from '../../../Components/CustomIcons/Flags/FlagColombia';
import NumberInput from '../../../Components/Inputs/NumberInput';
import ControlledCheckbox from '../../../Components/Controlled/ControlledCheckbox';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import Recaptcha from '../../../Components/Captcha/Recaptcha';
import PhoneVerificationDialog from '../../../Components/Dialogs/PhoneVerificationDialog';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';

import {
  StepperDataContext,
  StepperDataDispatchContext,
  SetCurrentStepIndexContext
} from '../../../Contexts/StepperContext';
import { UserContext } from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import { ValidateIdentificationAPI } from '../../../API/UserAPI';
import { SubmitGanaLocoAPI } from '../../../API/Campaigns/GanaLoco/GanaLocoAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { OpenNewTab } from '../../../Utils/Misc/Links';

import { firstStepSchema } from '../fixes/schemas';
import { config } from '../../../Configs';
import {
  GiveAwayPrivNotifLink,
  PrivacyPoliciesLink
} from '../../../Configs/Links';
import { parseLocalDateTo } from '../../../Utils/Date/parse';

const FirstStep = props => {
  const classes = useStyles();

  const {
    formId,
    setGoBack,
    setCanSubmit,
    setCurrentStep: setVisualCurrentStep
  } = props;

  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validIdentificationAlert, setValidIdentificationAlert] = useState(
    false
  );
  const [submittedValues, setSubmittedValues] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);

  const authToken = currentUser ? currentUser.token : null;

  const { register, watch, errors, control, handleSubmit } = useForm({
    validationSchema: firstStepSchema,
    submitFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      identification: '',
      phoneCountryCode: '57',
      phone: '',
      email: '',
      terms: false,
      ...stepperData
    }
  });

  const watchForm = watch([
    'identification',
    'terms',
    'phoneCountryCode',
    'phone'
  ]);

  const termsAndConditions = () => OpenNewTab(PrivacyPoliciesLink);

  const giveAwayPrivacyNotification = () => OpenNewTab(GiveAwayPrivNotifLink);

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const onCaptchaResolved = useCallback(token => {
    setCaptcha(token);
  }, []);

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  useEffect(() => {
    setGoBack({});
  }, [setGoBack]);

  useEffect(() => {
    if (
      stepperData.validIdentification &&
      watchForm.identification === stepperData.identification
    ) {
      setValidIdentificationAlert(true);
      return;
    }
    setValidIdentificationAlert(false);
  }, [
    watchForm.identification,
    stepperData.validIdentification,
    stepperData.identification
  ]);

  useEffect(() => {
    setCanSubmit(
      !loading &&
        !loadingCaptcha &&
        Boolean(captcha && captcha !== 'disable-captcha-validation') &&
        watchForm.terms
    );
  }, [loading, loadingCaptcha, setCanSubmit, captcha, watchForm.terms]);

  const onSuccessUserData = useCallback(
    async formValues => {
      const formattedDate = moment(formValues.expeditionDate).format(
        'YYYY-MM-DD'
      );

      setLoading(true);
      const response = await SubmitGanaLocoAPI(
        {
          step: 1,
          data: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            identification: formValues.identification,
            phoneCountryCode: formValues.phoneCountryCode,
            phone: formValues.phone,
            email: formValues.email
          }
        },
        authToken,
        captcha
      );

      if (!response.success) {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        return;
      }

      setData(oldData => ({
        ...oldData,
        ...formValues,
        expeditionDate: formattedDate
      }));
      setLoading(false);
      setCurrentStep(step => step + 1);
      setVisualCurrentStep(step => step + 1);
    },
    [
      setData,
      setCurrentStep,
      captcha,
      setAlert,
      setVisualCurrentStep,
      authToken
    ]
  );

  const handleOpenVerificationDialog = useCallback(
    values => {
      const {
        phone: currentPhone,
        phoneCountryCode: currentCountryCode
      } = values;
      const previousPhone = stepperData.phone;
      const previousCountryCode = stepperData.phoneCountryCode;
      const phoneHasChanged = previousPhone !== currentPhone;
      const countryCodeHasChanged = previousCountryCode !== currentCountryCode;

      if (!phoneHasChanged && !countryCodeHasChanged) {
        setSubmittedValues(null);
        onSuccessUserData(values);
        return;
      }
      setSubmittedValues(values);
      setOpenVerificationDialog(true);
    },
    [stepperData.phone, stepperData.phoneCountryCode, onSuccessUserData]
  );

  const verifyUserData = useCallback(
    async values => {
      const { identification, expeditionDate } = values;
      const formattedDate = moment(expeditionDate).format('YYYY-MM-DD');

      const identificationHasChanged =
        stepperData.identification !== identification;

      const expeditionDateHasChanged =
        stepperData.expeditionDate !== formattedDate;

      if (identificationHasChanged || expeditionDateHasChanged) {
        setData(oldData => ({
          ...oldData,
          validIdentification: false
        }));
        setLoading(true);

        const identificationData = {
          identification,
          identificationType: 'CC',
          expeditionDate: parseLocalDateTo(expeditionDate).toISOString()
        };

        const response = await ValidateIdentificationAPI(
          authToken,
          identificationData,
          captcha
        );

        if (!response.success) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: extractErrorMessage(response).message
          });
          return;
        }
      }
      setData(oldData => ({
        ...oldData,
        identification,
        validIdentification: true
      }));

      setLoading(false);
      handleOpenVerificationDialog(values);
    },
    [
      setAlert,
      stepperData.expeditionDate,
      stepperData.identification,
      handleOpenVerificationDialog,
      captcha,
      setData,
      authToken
    ]
  );

  return (
    <>
      {loading && <ModalProgress message="Consultando" />}
      {openVerificationDialog && (
        <PhoneVerificationDialog
          open={openVerificationDialog}
          setDialog={setOpenVerificationDialog}
          setAlert={setAlert}
          title="Validar número celular"
          successCallback={() => onSuccessUserData(submittedValues)}
          formPhone={watchForm.phone}
          formPhoneCountryCode={watchForm.phoneCountryCode}
        />
      )}
      <Box className={classes.shrinkedBox}>
        <form id={formId} onSubmit={handleSubmit(verifyUserData)}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <TextInput
                className={classes.normalizedInputField}
                label="Nombre*"
                fullWidth
                inputProps={{ maxLength: 50 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="firstName"
                helperText={errors.firstName && errors.firstName.message}
                error={Boolean(errors.firstName)}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <TextInput
                className={classes.normalizedInputField}
                label="Apellido*"
                fullWidth
                inputProps={{ maxLength: 50 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="lastName"
                helperText={errors.lastName && errors.lastName.message}
                error={Boolean(errors.lastName)}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <TextInput
                className={classes.normalizedInputField}
                label="Cédula de ciudadania*"
                fullWidth
                inputProps={{ maxLength: 20 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="identification"
                helperText={
                  errors.identification && errors.identification.message
                }
                error={Boolean(errors.identification)}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <TextInput
                label="Fecha de expedición*"
                inputProps={{
                  maxLength: 20,
                  max: moment()
                    .utcOffset(-5)
                    .format('YYYY-MM-DD')
                }}
                fullWidth
                inputRef={register}
                type="date"
                name="expeditionDate"
                InputLabelProps={{ shrink: true }}
                margin="none"
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.expeditionDate)}
                helperText={
                  errors.expeditionDate && errors.expeditionDate.message
                }
              />
            </Grid>
            {validIdentificationAlert && (
              <Grid item xs={12} sm={12} className={classes.sweetAlertGridItem}>
                <SweetAlert
                  type="success"
                  iconSize={24}
                  classes={{
                    root: classes.sweetAlert,
                    message: classes.sweetAlertText
                  }}
                  message={'Tus datos han sido validados exitosamente'}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} className={classes.nestedGrid}>
              <Controller
                as={
                  <NumberInput
                    className={classes.countryCodeGridItem}
                    id="RequestDialog_input_countryCode"
                    autoComplete="tel-country-code"
                    required
                    InputProps={{
                      startAdornment: (
                        <FlagColombia
                          width={16}
                          height={14}
                          style={{ flexShrink: 0 }}
                        />
                      )
                    }}
                    InputPropsClasses={{
                      root: classes.normalizedInputField
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.helperText
                      }
                    }}
                    format="+###"
                    placeholder="57"
                    fullWidth
                    label="Prefijo"
                    error={Boolean(errors.phoneCountryCode)}
                    helperText={
                      errors.phoneCountryCode && errors.phoneCountryCode.message
                    }
                    margin="none"
                  />
                }
                control={control}
                name="phoneCountryCode"
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].value;
                  }
                  return '';
                }}
              />
              <Controller
                as={
                  <NumberInput
                    className={classes.phoneGridItem}
                    id="RequestDialog_input_phone"
                    autoComplete="tel-local"
                    fullWidth
                    margin="none"
                    label="Telefóno*"
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                    InputPropsClasses={{
                      root: classes.normalizedInputField
                    }}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.helperText
                      }
                    }}
                  />
                }
                control={control}
                name="phone"
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].value;
                  }
                  return '';
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <TextInput
                className={classes.normalizedInputField}
                label="Correo electrónico*"
                fullWidth
                inputProps={{ maxLength: 60 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="email"
                helperText={errors.email && errors.email.message}
                error={Boolean(errors.email)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              ref={inputRef}
              className={classes.recaptcha}
            >
              <Recaptcha
                captchaRef={recaptchaRef}
                referenceEl={inputRef}
                heightScale={0.75}
                locale={'es'}
                sitekey={config.recaptcha.siteKey}
                onResolved={onCaptchaResolved}
                onExpired={onCaptchaExpired}
                onLoaded={onCaptchaLoaded}
              />
            </Grid>
          </Grid>
          <SimpleDivider className={classes.simpleDivider} />
          <Box>
            <Typography
              variant="body2"
              component="p"
              className={classes.privacyTextInfo}
            >
              Informamos que los datos personales recolectados serán tratados de
              conformidad con la Política de Tratamiento de Datos Personales de
              Gascaribe que puede ser consultada{' '}
              <strong onClick={termsAndConditions}>aquí</strong>. Y de
              conformidad con el aviso de privacidad para sorteos que puede ser
              consultado{' '}
              <strong onClick={giveAwayPrivacyNotification}>aquí</strong>.
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              className={classes.termsTextAndCheckboxContainer}
              control={
                <ControlledCheckbox
                  classes={classes.termsCheckbox}
                  name="terms"
                  control={control}
                  disabled={false}
                  checked={watchForm.terms}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={
                <Typography className={classes.termsText}>
                  ¿Autoriza de forma expresa, previa e informada el{' '}
                  <span className={classes.boldText}>
                    tratamiento de sus datos personales
                  </span>
                  ?
                </Typography>
              }
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    maxWidth: '100%',
    margin: 0,
    justifyContent: 'space-between'
  },
  shrinkedBox: {
    width: '88.89%',
    margin: '0 auto'
  },
  pairGridItem: {
    margin: theme.spacing(0, 0, 3.75, 0),
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(odd)': {
        paddingRight: theme.spacing(2)
      },
      '&:nth-child(even)': {
        paddingLeft: theme.spacing(2)
      }
    }
  },
  normalizedInputField: {
    margin: 0,
    minHeight: 50,
    maxHeight: 50
  },
  countryCodeGridItem: {
    maxWidth: '100px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '82px'
    }
  },
  phoneGridItem: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '142px'
    }
  },
  nestedGrid: {
    display: 'flex',
    margin: theme.spacing(0, 0, 3.75, 0),
    maxHeight: 50,
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  sweetAlertGridItem: {
    margin: theme.spacing(0, 0, 3.75, 0),
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(7),
      margin: theme.spacing(0, 0, 3.75, 0)
    }
  },
  sweetAlert: {
    opacity: 1,
    color: theme.palette.common.black,
    height: theme.spacing(7)
  },
  sweetAlertText: {
    fontSize: 12
  },
  simpleDivider: {
    margin: theme.spacing(0, 0, 2.25, 0)
  },
  privacyTextInfo: {
    color: theme.palette.color.default,
    fontSize: 12,
    marginBottom: theme.spacing(3),
    '& > strong': {
      fontWeight: 'bold',
      color: theme.palette.color.default,
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  termsText: {
    color: theme.palette.color.default,
    fontSize: 12,
    marginBottom: theme.spacing(12.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4)
    }
  },
  termsTextAndCheckboxContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  helperText: {
    fontSize: 10,
    margin: 0,
    textAlign: 'right'
  },
  recaptcha: {
    margin: theme.spacing(0, 0, 3.75, 0),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 240,
      margin: theme.spacing(0, 0, 3, 0)
    }
  },
  boldText: {
    fontWeight: 700
  }
}));

export default FirstStep;
