import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { SlideUpTransition } from '../Transitions/Transitions';
import CloseIcon from '../CustomIcons/CloseIcon';

const BANNERS_CONFIRMATION_PSE_BANNER_TRY_AGAIN = require('../../Assets/images/BANNERS_CONFIRMACION_PSE_BANNER_INTENTA_NUEVAMENTE.jpg');
const PSE_CONFIRMATION_BANNERS_WINNER_BANNER = require('../../Assets/images/BANNERS_CONFIRMACION_PSE_BANNER_GANADOR.jpg');

const PagaGanaDialog = props => {
  const { onClose, Win = false, open = true, discount } = props;
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));

  const content = Win
    ? PSE_CONFIRMATION_BANNERS_WINNER_BANNER
    : BANNERS_CONFIRMATION_PSE_BANNER_TRY_AGAIN;

  const textContent = Win ? (
    <>
      <p style={{ lineHeight: '20px', fontSize: '12px' }}>
        ¡Hola!
        <br />
        En Efigas estamos muy felices de informarte que ganaste un descuento en
        tu próxima factura.
        <br />
        El valor que se te descontará es: <strong>{discount}</strong>
      </p>
      <div style={{ fontSize: '9px', textAlign: 'start' }}>
        ¡Recuerda! La transacción está sujeta a verificación. Por ello, si con
        posterioridad el pago que realices registra como no exitoso o rechazado,
        perderás el beneficio. Para mayor información consulta los términos y
        condiciones:{' '}
        <a
          href="https://clientes.efigas.com.co/Servicios/Politicas/politicapse"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#007bff', textDecoration: 'underline' }}
        >
          aquí
        </a>
        .
      </div>
    </>
  ) : (
    <p style={{ lineHeight: '15px', fontSize: '11px', marginTop: '20px' }}>
      Sigue pagando por PSE para que tengas más oportunidades de ganar hasta
      $50.000 de descuento en tu próxima factura. Para más información consulta
      los términos y condiciones:{' '}
      <a
        href="https://clientes.efigas.com.co/Servicios/Politicas/politicapse"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#007bff', textDecoration: 'underline' }}
      >
        aquí
      </a>
      .
    </p>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      PaperProps={{
        style: {
          position: 'relative',
          overflow: 'hidden',
          borderRadius: theme.custom.borderRadius || 8,
          width: '630px'
        }
      }}
    >
      <IconButton
        aria-label="cerrar diálogo"
        onClick={onClose}
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}
      >
        <img
          width="100%"
          alt="Paga y Gana"
          src={content}
          style={{
            objectFit: 'cover',
            display: 'block',
            maxHeight: '70%'
          }}
        />
        <div
          style={{
            padding: '5px 30px 20px 30px',
            backgroundColor: '#f9f9f9',
            color: '#333',
            textAlign: 'center',
            fontSize: '14px',
            lineHeight: '1.5'
          }}
        >
          {textContent}
        </div>
      </div>
    </Dialog>
  );
};

export default PagaGanaDialog;
